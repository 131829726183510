<template>
  <div :key="pages">
    <pagination :page="page" :pages="pages" :callback="callback" />
  </div>
</template>
<script>
export default {
  components: {
    pagination () { return import('./pagination.vue') }
  },
  props: {
    clients: Array,
    page: Number,
    breakpoint: Number,
    callback: Function
  },
  data () {
    return {
      pages: 0
    }
  },
  computed: {

  },
  mounted () {
    this.pages = Math.ceil(this.clients.length / this.breakpoint)
  },
  methods: {
  }
}
</script>
<style lang="sass" scoped>
</style>
